<template>
    <div>
        <b-col cols="3">
            <p>Filtrer par date:</p>
            <b-col>
                <label for="example-datepicker"> Date de debut </label>
                <b-form-datepicker v-model="startDate" class="mb-2"></b-form-datepicker>
            </b-col>
            <b-col>
                <label for="example-datepicker"> Date de fin </label>
                <b-form-datepicker v-model="endDate" class="mb-2"></b-form-datepicker>
            </b-col>
        </b-col>
        <b-row>
            <b-col cols="6">
                <b-button size="sm" class="mb-2 w-15 d-flex ml-auto" variant="primary" @click="downloadReport(campaigns)"> Télecharger </b-button>
            </b-col>
            <b-input-group class="mb-1 w-25 d-flex ml-auto">
                <b-input-group-prepend>
                    <b-input-group-text>
                        <feather-icon icon="SearchIcon"></feather-icon>
                    </b-input-group-text>
                </b-input-group-prepend>
                <b-input v-model="searchFilter"></b-input>
            </b-input-group>
        </b-row>
        <b-table
            ref="table"
            striped
            responsive
            :filter="searchFilter"
            :end="endDate"
            :startdate="startDate"
            :items="campaignsForTable"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            hover
            @row-clicked="toggleRow"
            @filtered="onTableFiltered"
        >
            <template #row-details="row">
                <h5 class="text-primary">Commandé</h5>
                <small>
                    SMS text: <b>{{ row.item.details.sms_text }}</b></small
                >
                <br /><br />
                <b-container class="pad-0" style="max-width: 90%; margin: inherit">
                    <b-row>
                        <b-col><strong>Volume</strong></b-col>
                        <b-col><strong>Prix</strong></b-col>
                        <b-col><strong> Date </strong></b-col>
                        <b-col><strong>Type SMS </strong></b-col>
                        <b-col><strong> Label </strong></b-col>
                        <b-col><strong> Type </strong></b-col>
                        <b-col><strong> Produit </strong></b-col>
                    </b-row>
                    <b-row>
                        <b-col>{{ row.item.details.volume }}</b-col>
                        <b-col>{{ row.item.details.price + '€' }}</b-col>
                        <b-col>{{ row.item.details.date }}</b-col>
                        <b-col>{{ row.item.details.smsType }}</b-col>
                        <b-col>{{ row.item.details.label }}</b-col>
                        <b-col>{{ row.item.details.type }}</b-col>
                        <b-col>{{ row.item.details.product }}</b-col>
                    </b-row>
                </b-container>
                <div v-if="row.item.details.criterias">
                    <h5 class="text-primary">Critères</h5>
                    <b-container class="pad-0" style="max-width: 60%; margin: inherit">
                        <b-row>
                            <b-col><strong>Genre</strong></b-col>
                            <b-col><strong> AgeMin </strong></b-col>
                            <b-col><strong> AgeMax </strong></b-col>
                            <b-col><strong> Criterias </strong></b-col>
                        </b-row>
                        <b-row>
                            <b-col>{{ row.item.details.gender }}</b-col>
                            <b-col>{{ row.item.details.ageMin }}</b-col>
                            <b-col>{{ row.item.details.ageMax }}</b-col>
                            <b-col> {{ row.item.details.criterias }}</b-col>
                        </b-row>
                    </b-container>
                </div>
                <template> </template>
                <h5 class="text-primary">Infos tech</h5>
                <b-container class="pad-0" style="max-width: 90%; margin: inherit">
                    <b-row>
                        <b-col
                            ><strong
                                ><small>
                                    Nom campagne : <b>{{ row.item.details.label_quotation }}</b></small
                                ></strong
                            ></b-col
                        >
                        <b-col
                            ><strong
                                ><small>
                                    Id Caldia : <b>{{ row.item.details.id_caldia }}</b></small
                                >
                            </strong></b-col
                        >
                    </b-row>
                    <b-row>
                        <b-col><strong>Provider Id</strong></b-col>
                        <b-col><strong>Statut</strong></b-col>
                        <b-col><strong>Prestataire</strong></b-col>
                    </b-row>
                    <b-row>
                        <b-col>{{ row.item.details.provider_data.id }}</b-col>
                        <b-col>{{ row.item.status }}</b-col>
                        <b-col>{{ row.item.details.provider }}</b-col>
                    </b-row>
                    <b-row>
                        <b-col><strong>Code de la campagne</strong></b-col>
                    </b-row>
                    <b-row>
                        <b-col>{{ row.item.campaign_code }}</b-col>
                    </b-row>
                </b-container>
            </template>
        </b-table>
        <b-pagination class="mt-3" v-model="currentPage" :total-rows="rows" :per-page="perPage" first-number></b-pagination>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import FileSaver from 'file-saver';

    export default {
        props: {
            campaigns: {
                type: Array,
                required: true,
            },
            right: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                currentCampaignCode: null,
                currentPage: 1,
                perPage: 10,
                searchFilter: '',
                dataFiltred: [],
                endDate: '',
                startDate: '',
                fields: [
                    {
                        key: 'brand',
                        label: 'Marque',
                    },
                    {
                        key: 'details.point_text',
                        label: 'Point de vente',
                    },
                    {
                        key: 'point_code',
                        label: 'Point Code',
                    },
                    {
                        key: 'start_date',
                        label: 'Date de début',
                    },
                    {
                        key: 'end_date',
                        label: 'Date de fin',
                    },
                    {
                        key: 'status',
                        label: 'Statut',
                        tdClass: 'cell-status',
                    },
                ],
                rows: 0,
            };
        },
        computed: {
            ...mapGetters({
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
            campaignsForTable() {
                let data = this.campaigns.map((camp) => {
                    return {
                        ...camp,
                        _showDetails: false,
                    };
                });

                if (this.startDate !== '') {
                    let start = this.startDate;
                    let newArray = data.filter(function (item) {
                        return item.start_date >= start;
                    });
                    data = newArray;
                }

                if (this.endDate !== '') {
                    let end = this.endDate;
                    let newArray = data.filter(function (item) {
                        return item.start_date <= end;
                    });
                    data = newArray;
                }

                if (this.searchFilter === '') this.setRows(data);

                return data;
            },
        },
        methods: {
            setRows(list) {
                this.$set(this, 'dataFiltred', list);
                this.rows = list.length;
            },
            onTableFiltered(list) {
                this.setRows(list);
            },
            toggleRow(row) {
                if (row._showDetails === false) {
                    this.$root.toggleGlobalLoading(true);
                    this.$store
                        .dispatch('app/getSMSInfos', {
                            code: row.campaign_code,
                        })
                        .then((res) => {
                            let resCampaign = res.data;
                            return resCampaign;
                        })
                        .catch((e) => {
                            console.error(e);
                        })
                        .finally(() => {
                            this.$root.toggleGlobalLoading(false);
                        });
                }
                row._showDetails = !row._showDetails;
            },
            downloadReport() {
                let data = this.dataFiltred;
                if (data <= 0) {
                    return this.$toast({
                        component: 'toast',
                        props: {
                            title: 'Aucune campagne trouvée',
                            icon: 'x-circle',
                            variant: 'danger',
                        },
                    });
                }

                this.$store
                    .dispatch('app/getSMSTemplate', { data })
                    .then((res) => {
                        let file = res.data;
                        FileSaver.saveAs(file, `report.xlsx`);
                        this.$toast({
                            component: 'toast',
                            props: {
                                title: 'Le rapport a été téléchargé',
                                icon: 'smile',
                                variant: 'success',
                            },
                        });
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Connction erreur',
                                icon: 'x-circle',
                                variant: 'danger',
                            },
                        });
                    });
            },
        },
    };
</script>

<style lang="scss">
    .cell-status {
        max-width: 200px;

        .badge {
            white-space: normal;
        }
    }

    .pad-0 {
        padding: 0 !important;
    }

    .red {
        color: red;
    }

    .align-items-center {
        align-items: center;
    }
</style>
